interface RegistrationParameters {
  promo?: string
  utm?: {
    [propName: string]: string
  }
}

export default (): RegistrationParameters => {
  const paramsString = localStorage.getItem('registration-params')
  if (!paramsString) return {}

  const paramsJSON = JSON.parse(paramsString)
  return Object.keys(paramsJSON).reduce((acc, key) => {
    const utm = acc.utm || {}

    if (key === 'promo') {
      const promo = paramsJSON.promo
      return { ...acc, promo }
    }

    if (key.includes('utm_')) {
      return {
        ...acc,
        utm: {
          ...utm,
          [key]: paramsJSON[key],
        },
      }
    }

    return acc
  }, {} as RegistrationParameters)
}
